import firebase from "firebase/app";
import "firebase/auth";

import getConfig from "src/utils/config";

export function getFirebase(): firebase.app.App {
  if (firebase.apps.length === 0) {
    const app = firebase.initializeApp(getConfig().firebase);
    firebase.auth().useDeviceLanguage();
    return app;
  }
  return firebase.app();
}

export function getFirebaseAuth(): firebase.auth.Auth {
  return getFirebase().auth();
}

export function getFirebaseUser(): firebase.User | null {
  return getFirebase().auth().currentUser;
}

export async function login(email: string, password: string) {
  try {
    const user = await getFirebaseAuth().signInWithEmailAndPassword(
      email,
      password
    );
    return [user, null];
  } catch (err) {
    return [null, err];
  }
}

export async function logOut() {
  return getFirebaseAuth().signOut();
}

export function getFirebaseUserName(displayName: string | null | undefined) {
  const userDetails = {
    givenName: "",
    familyName: ""
  };

  if (!displayName) return userDetails;

  if (displayName) {
    const [givenName, ...familyNameParts] = displayName.split(" ");
    const familyName = familyNameParts.join(" ");
    userDetails.givenName = givenName;

    if (familyName) {
      userDetails.familyName = familyName;
    }
  }

  return userDetails;
}
