import { Icon, BackgroundInvertedProvider } from "@seneca/senekit";

import styles from "../Homepage.module.scss";

export default function LoadingSpinner() {
  return (
    <div className={styles.spinnerContainer}>
      <div className={styles.spinner}>
        <BackgroundInvertedProvider>
          <Icon
            iconName="senecaLoader"
            size="fill"
            accessibilityLabel="Loading..."
          />
        </BackgroundInvertedProvider>
      </div>
    </div>
  );
}
