export interface Action<T> {
  type: T;
  [key: string]: any;
}

export function combineReducers<T, K>(slices: ((state: T, action: Action<K>) => T)[]) {
  return (state: T, action: Action<K>): T =>
    slices.reduce(
      (acc: T, slice) => ({
        ...acc,
        ...slice(acc, action)
      }),
      state
    );
}
