import { PowerUpActions } from "src/hooks/power-ups/usePowerUps";
import { Dispatch } from "react";
import { Action } from "src/utils/combineReducers";

export function useFiftyFifty(
  dispatch: Dispatch<Action<PowerUpActions>>,
  questionId: string
) {
  dispatch({ type: PowerUpActions.FIFTY_FIFTY_STARTED });
  fetch(
    `https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/fetch5050Options?questionId=${questionId}`,
    {
      method: "GET"
    }
  )
    .then((res) => res.json())
    .then((json) =>
      dispatch({
        type: PowerUpActions.FIFTY_FIFTY_FINISHED,
        filteredOptions: json.items
      })
    )
    .catch(() => dispatch({ type: PowerUpActions.FIFTY_FIFTY_FAILED }));
}
