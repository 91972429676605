export default async function checkIfUserExists(userId: string) {
  try {
    const res = await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/checkIfUserExists",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ userId })
      }
    );

    return res.ok;
  } catch (error) {
    return false;
  }
}
