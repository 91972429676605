import { PowerUpActions, PowerUpState } from "src/hooks/power-ups/usePowerUps";
import { Action } from "src/utils/combineReducers";

export default function reducer(state: PowerUpState, action: Action<PowerUpActions>): PowerUpState {
  switch (action.type) {
    case PowerUpActions.IS_POWER_UP_USED_LOADING:
      return {
        ...state,
        hasPowerUpBeenUsedBefore: true
      };
    case PowerUpActions.IS_POWER_UP_USED_FINISHED:
      return  {
        ...state,
        hasPowerUpBeenUsedBefore: action.isPowerUpUsed
      };
    case PowerUpActions.IS_POWER_UP_USED_FAILED:
      return {
        ...state,
        hasPowerUpBeenUsedBefore: false
      }
    default:
      return state;
  }
}
