import { PowerUp, PowerUpActions, PowerUpState } from "src/hooks/power-ups/usePowerUps";
import { Action } from "src/utils/combineReducers";

export interface FiftyFiftyState {
  loading: boolean;
  filteredOptions: string[] | null;
  error: boolean;
}

export default function reducer(state: PowerUpState, action: Action<PowerUpActions>): PowerUpState {
  switch (action.type) {
    case PowerUpActions.FIFTY_FIFTY_STARTED:
      return {
        ...state,
        selectedPowerUp: PowerUp.FIFTY_FIFTY,
        fiftyFifty: {
          loading: true,
          filteredOptions: null,
          error: false,
        }
      };
    case PowerUpActions.FIFTY_FIFTY_FINISHED:
      return {
        ...state,
        selectedPowerUp: PowerUp.FIFTY_FIFTY,
        hasPowerUpBeenUsedToday: true,
        fiftyFifty: {
          loading: false,
          filteredOptions: action.filteredOptions,
          error: false,
        }
      };
    case PowerUpActions.FIFTY_FIFTY_FAILED:
      return {
        ...state,
        selectedPowerUp: null,
        hasPowerUpBeenUsedToday: false,
        fiftyFifty: {
          loading: false,
          filteredOptions: null,
          error: true,
        }
      };
    default:
      return state;
  }
}
