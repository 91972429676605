export default async function fetchTodaysQuestions(userId: string) {
  try {
    const res = await fetch(
      `https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/fetchTodaysQuestions/${userId}`,
      {
        method: "GET"
      }
    ).then((res) => res.json());

    return res;
  } catch (error) {
    return null;
  }
}
