import * as React from "react";
import firebase from "firebase/app";
import { getFirebaseAuth } from "src/services/firebase";
import { checkIfUserExists } from "src/api";

type User = firebase.User | null | undefined;
type ContextState = {
  user: User;
  isAuth: boolean;
  userInfoExists: boolean;
  setUserInfoExists: (exists: boolean) => void;
};

export const FirebaseAuthContext = React.createContext<
  ContextState | undefined
>(undefined);

const FirebaseAuthProvider: React.FC = ({ children }) => {
  const [isLoading, setIsLoading] = React.useState<boolean>(false);
  const [user, setUser] = React.useState<User>(undefined);
  const [userInfoExists, setUserInfoExists] = React.useState<boolean | null>(
    null
  );

  React.useEffect(() => {
    if (user) {
      (async () => {
        const userExists = await checkIfUserExists(user.uid);
        setUserInfoExists(userExists);
      })();
    }
  }, [user]);

  const value = {
    user,
    isLoading,
    setIsLoading,
    isAuth: !isLoading && !!user,
    userInfoExists,
    setUserInfoExists
  };

  React.useEffect(() => {
    const unsubscribe = getFirebaseAuth().onAuthStateChanged(setUser);
    return unsubscribe;
  }, []);

  return (
    <FirebaseAuthContext.Provider value={value}>
      {children}
    </FirebaseAuthContext.Provider>
  );
};

export default FirebaseAuthProvider;
