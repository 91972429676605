import { useEffect, useState } from 'react';
import { withRouter } from 'react-router';
import { Redirect, Route, Switch } from 'react-router-dom';
import { usePowerUps } from 'src/hooks/power-ups/usePowerUps';
import useFirebaseAuth from 'src/hooks/useFirebaseAuth';

import CreateQuestion from '../pages/CreateQuestion';
import GenerateQuestion from '../pages/GenerateQuestion';
import Homepage from '../pages/Homepage';
import QuizQuestion from '../pages/QuizQuestion';

function Routes() {
  const { user } = useFirebaseAuth();
  const userId = user?.uid;
  const [question, setQuestionUnsafe] = useState(null);
  const [answered, setAnswered] = useState(null);
  const [isNoQuestionLeft, setIsNoQuestionLeft] = useState(null);
  const powerUpState = usePowerUps(question?.questionId);

  useEffect(() => {
    powerUpState.getIsPowerUpUsed(userId!);
  }, [userId]);

  const setQuestion = (question: any) => {
    const { userId, ...safeQuestion } = question;
    setQuestionUnsafe(safeQuestion);
  };

  return (
    <Switch>
      {question && (
        <Route path="/quiz">
          {/* {answered && <Redirect to="/" />} */}
          <QuizQuestion
            {...question}
            userId={userId}
            answered={answered}
            setAnswered={setAnswered}
            powerUpState={powerUpState}
          />
        </Route>
      )}

      <Route path="/create">
        <CreateQuestion userId={userId} />
      </Route>

      <Route path="/generate">
        <GenerateQuestion userId={userId} />
      </Route>

      <Route path="/" exact>
        <Homepage
          userId={userId}
          answered={answered}
          setQuestion={setQuestion}
          setAnswered={setAnswered}
          isNoQuestionLeft={isNoQuestionLeft}
          setIsNoQuestionLeft={setIsNoQuestionLeft}
          questionId={question && question.questionId}
        />
      </Route>

      <Redirect to="/" />
    </Switch>
  );
}

export default withRouter(Routes);
