import { useEffect, useState } from "react";
import classnames from "classnames";

import {
  fetchTodaysScores,
  fetchLeaderboard,
  fetchTodaysQuestions
} from "src/api";

import Leaderboard from "./components/Leaderboard";
import AnswerQuestion from "./components/AnswerQuestion";

import styles from "./Homepage.module.scss";
import { useSetSenequizState } from "src/context/State";

export default function Homepage({
  userId,
  answered,
  setAnswered,
  setQuestion,
  questionId,
  isNoQuestionLeft,
  setIsNoQuestionLeft
}) {
  const today = new Date();
  const isQuizDay = today.getDay() !== 0 && today.getDay() !== 6;

  const [leaderboards, setLeaderboards] = useState(null);
  const [leaderboardsWeekly, setLeaderboardsWeekly] = useState(null);
  const [todayScores, setTodaysScores] = useState(undefined);
  const [leaderboardIndex, setLeaderboardIndex] = useState(null);
  const [leaderboardIndexWeekly, setLeaderboardIndexWeekly] = useState(null);

  const setSenequizState = useSetSenequizState();

  useEffect(() => {
    (async () => {
      if (userId && !questionId && isQuizDay) {
        const fetchTodaysQuestionsRes = await fetchTodaysQuestions(userId);
        if (fetchTodaysQuestionsRes && fetchTodaysQuestionsRes.items[0]) {
          setQuestion(fetchTodaysQuestionsRes.items[0]);
          setAnswered(fetchTodaysQuestionsRes.answered);
          setIsNoQuestionLeft(false);

          setSenequizState((s) => ({
            ...s,
            numberOfQuestionsLeft: fetchTodaysQuestionsRes.unansweredTotal
          }));
        } else {
          setIsNoQuestionLeft(true);
        }
      } else {
        setIsNoQuestionLeft(false);
        // setAnswered(false);
      }
    })();
  }, [userId, setQuestion, setAnswered, questionId, isQuizDay]);

  useEffect(() => {
    (async () => {
      const [
        fetchTodaysScoresRes,
        fetchLeaderboardMonthRes,
        fetchLeaderboardWeekRes
      ] = await Promise.all([
        fetchTodaysScores(),
        fetchLeaderboard(),
        fetchLeaderboard(true)
      ]);

      setLeaderboards(fetchLeaderboardMonthRes);
      setLeaderboardIndex(fetchLeaderboardMonthRes.length - 1);

      setLeaderboardsWeekly(fetchLeaderboardWeekRes);
      setLeaderboardIndexWeekly(fetchLeaderboardWeekRes.length - 1);

      setTodaysScores(fetchTodaysScoresRes);
    })();
  }, []);

  return (
    <div className={styles.wrapper}>
      <div className={classnames(styles.section, styles.wide)}>
        <h1>Welcome to SeneQuiz</h1>
      </div>
      <AnswerQuestion
        answered={answered}
        isQuizDay={isQuizDay}
        isNoQuestionLeft={isNoQuestionLeft}
      />

      <Leaderboard
        leaderboards={leaderboards}
        leaderboardIndex={leaderboardIndex}
        setLeaderboardIndex={setLeaderboardIndex}
        todayScores={todayScores}
      />

      <Leaderboard
        leaderboards={leaderboardsWeekly}
        leaderboardIndex={leaderboardIndexWeekly}
        setLeaderboardIndex={setLeaderboardIndexWeekly}
        todayScores={todayScores}
      />
    </div>
  );
}
