import { useState } from "react";

import { login } from "src/services/firebase";

import useFirebaseAuth from "src/hooks/useFirebaseAuth";

import styles from "../Homepage.module.scss";

export default function Auth() {
  const { isLoading, setIsLoading } = useFirebaseAuth();
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");

  const loginUser = async () => {
    setIsLoading(true);
    const [userRes] = await login(email, password);
    setIsLoading(false);
  };

  return (
    <div className={styles.section}>
      <h2>Login</h2>
      <input
        type="email"
        onChange={(e) => {
          setEmail(e.target.value);
        }}
        value={email}
        className={styles.input}
        placeholder="Seneca email"
      />

      <input
        type="password"
        onChange={(e) => {
          setPassword(e.target.value);
        }}
        value={password}
        className={styles.input}
        placeholder="Password"
      />
      <button style={{ maxWidth: "280px" }} onClick={loginUser}>
        {isLoading ? "Loading..." : "Login"}
      </button>
    </div>
  );
}
