import { useState } from "react";
import "@fontsource/muli";
import { createPortal } from "react-dom";
import {
  BrowserRouter,
  Redirect,
  Route,
  Switch,
  Link,
  NavLink
} from "react-router-dom";

import { DomProvider, ThemeProvider, themes } from "@seneca/senekit";

import FirebaseAuthProvider from "src/context/FirebaseAuthContext";

import Header from "src/components/Header";
import Routes from "src/pages/Routes";
import { SeneQuizStateProvider } from "./context/State";

export default function App() {
  return (
    <BrowserRouter>
      <DomProvider value={{ createPortal, Link, NavLink }}>
        <ThemeProvider theme={themes.freeTheme}>
          <FirebaseAuthProvider>
            <SeneQuizStateProvider>
              <Header />
              <Routes />
            </SeneQuizStateProvider>
          </FirebaseAuthProvider>
        </ThemeProvider>
      </DomProvider>
    </BrowserRouter>
  );
}
