import { useState } from "react";
import { sample } from "lodash";
import { FiCheck, FiX } from "react-icons/fi";
import { useHistory } from "react-router-dom";

import QuestionOption from "./components/QuestionOption";

import Image from "../../components/Image";

import { answerTodaysQuestions } from "src/api";
import { PowerUp } from "src/hooks/power-ups/usePowerUps";

import styles from "./QuizQuestion.module.scss";

export default function QuizQuestion({
  userId,
  options,
  question,
  questionId,
  answered,
  setAnswered,
  source,
  imageUrl,
  powerUpState
}) {
  const history = useHistory();
  const [isLoading, setIsLoading] = useState(false);
  const [userResponse, setUserResponse] = useState(null);
  const [result, setResult] = useState("default - something went wrong");

  const {usePowerUp} = powerUpState;

  const onSubmit = async () => {
    setIsLoading(true);
    const answerTodaysQuestionsRes = await answerTodaysQuestions(
      userId,
      questionId,
      userResponse,
      !!powerUpState.selectedPowerUp,
      powerUpState.selectedPowerUp
    );
    setResult(answerTodaysQuestionsRes);
    setAnswered(true);
    setIsLoading(false);
  };

  const getOptions = () => {
    if (powerUpState.fiftyFifty && powerUpState.fiftyFifty.filteredOptions) {
      return options.map(option => powerUpState.fiftyFifty.filteredOptions.includes(option) ? option : "");
    }

    return options;
  }

  return (
    <div className={styles.wrapper}>
      <h2>{question}</h2>
      {imageUrl && <Image imageUrl={imageUrl} />}

      {answered ? (
        <>
          <p style={{ textAlign: "center" }}>
            {result.score > 0
              ? `That's correct! ${sample(["🎉", "🥳", "🎊"])}`
              : `That's not quite right.. ${sample([
                  "🙃",
                  "💩",
                  "👻",
                  "👽",
                  "😬"
                ])}`}
          </p>

          <Score score={result.score} />
          {result.score > 0 ? (
            <p style={{ textAlign: "center" }}>
              The answer was{" "}
              <strong>{capitalizeFirstLetter(result.correctOption)}</strong>
            </p>
          ) : (
            <>
              <p style={{ textAlign: "center" }}>
                The answer was{" "}
                <strong>{capitalizeFirstLetter(result.correctOption)}</strong>
              </p>
              <p style={{ textAlign: "center" }}>
                You chose{" "}
                <strong>{capitalizeFirstLetter(result.answer)}</strong>
              </p>
            </>
          )}
          {source && (
            <p style={{ textAlign: "center" }}>
            <strong>Source:</strong> {source}
          </p>
          )}

          <div className={styles.button}>
            <button
              onClick={() => {
                history.push("/");
              }}
            >
              OK
            </button>
          </div>
        </>
      ) : (
        <>
          <div className={styles.answers}>
            {!powerUpState.fiftyFifty?.loading ? getOptions().map((option, index) => (
              <QuestionOption
                key={option}
                value={option}
                active={userResponse === option}
                onClick={() => setUserResponse(option)}
                isCorrect={index === 0 ? powerUpState.isTopLeft?.isTopLeft : undefined}
              />
            )) : <p>Loading...</p>}
          </div>
          <div className={styles.button}>
            <button onClick={onSubmit}>
              {isLoading ? "Checking... 👀" : "Submit"}
            </button>
          </div>
          <div>
            <h3>Power Ups</h3>
            <PowerUpUsedMessage powerUp={powerUpState.selectedPowerUp}/>
            <p>{(powerUpState.hasPowerUpBeenUsedBefore || powerUpState.selectedPowerUp) ? "You have used your power up for this week" : "You can use 1 power up a week 🚀"}</p>
            {!powerUpState.hasPowerUpBeenUsedBefore && !powerUpState.selectedPowerUp && <div className={styles.powerUpWrapper}>
            <button onClick={() => usePowerUp(PowerUp.FIFTY_FIFTY)}>50:50</button>
            <button onClick={() => usePowerUp(PowerUp.IS_TOP_LEFT, options[0])}>Is Top Left?</button>
              <button onClick={() => usePowerUp(PowerUp.DOUBLE_POINTS)}>Double Points</button>
            </div>}
          </div>
        </>
      )}
    </div>
  );
}

const Score = ({ score }) => {
  if (score === 2) return <FiCheck className={styles.double} />;
  if (score === 1) return <FiCheck className={styles.right} />;
  if (score === 0) return <FiX className={styles.wrong} />;
  return null;
};

const PowerUpUsedMessage = ({powerUp}) => {
  switch (powerUp) {
    case PowerUp.DOUBLE_POINTS:
      return <h4>Double points activated!</h4>
    default:
      return null;
  }
}

function capitalizeFirstLetter(string) {
  return string.charAt(0).toUpperCase() + string.slice(1);
}
