import { useReducer } from "react";
import { useFiftyFifty } from "./actions/fiftyFiftyActions";
import { getIsPowerUpUsed } from "./actions/checkIfPowerUpUsedActions";
import reducer from "./reducers/powerUpReducer";
import { FiftyFiftyState } from "./reducers/fiftyFiftyReducer";
import { CheckIfTopLeftState } from "src/hooks/power-ups/reducers/checkIfTopLeftReducer";
import { useIsTopLeft } from "src/hooks/power-ups/actions/checkIfTopLeftActions";

export enum PowerUp {
  FIFTY_FIFTY = "FIFTY_FIFTY",
  IS_TOP_LEFT = "IS_TOP_LEFT",
  DOUBLE_POINTS = "DOUBLE_POINTS"
}

export enum PowerUpActions {
  IS_POWER_UP_USED_LOADING,
  IS_POWER_UP_USED_FINISHED,
  IS_POWER_UP_USED_FAILED,
  FIFTY_FIFTY_STARTED,
  FIFTY_FIFTY_FINISHED,
  FIFTY_FIFTY_FAILED,
  IS_TOP_LEFT_STARTED,
  IS_TOP_LEFT_FINISHED,
  IS_TOP_LEFT_FAILED,
  USE_DOUBLE_POINTS
}

export interface PowerUpState {
  selectedPowerUp: PowerUp | null;
  hasPowerUpBeenUsedBefore: boolean;
  fiftyFifty: FiftyFiftyState | null;
  isTopLeft: CheckIfTopLeftState | null;
}

interface PowerUpReturnState extends PowerUpState {
  usePowerUp: (powerUp: PowerUp, ...args: any[]) => void;
  getIsPowerUpUsed: (userId: string) => void;
}

export function usePowerUps(questionId: string): PowerUpReturnState {
  const initialState: PowerUpState = {
    selectedPowerUp: null,
    hasPowerUpBeenUsedBefore: false,
    fiftyFifty: null,
    isTopLeft: null
  };

  const [state, dispatch] = useReducer(reducer, initialState);

  function usePowerUp(powerUpType: PowerUp, ...args: any[]) {
    switch (powerUpType) {
      case PowerUp.IS_TOP_LEFT:
        return useIsTopLeft(dispatch, questionId)(args[0]);
      case PowerUp.DOUBLE_POINTS:
        return dispatch({type: PowerUpActions.USE_DOUBLE_POINTS});
      default:
        return useFiftyFifty(dispatch, questionId);
    }
  }

  return {...state, usePowerUp, getIsPowerUpUsed: getIsPowerUpUsed(dispatch)};
}
