import { Link } from "react-router-dom";
import useFirebaseAuth from "src/hooks/useFirebaseAuth";
import { logOut } from "src/services/firebase";

import { Icon, Stack } from "@seneca/senekit";

// @ts-ignore
import logo from "../../assets/logo.svg";
import { useSenequizState } from "../../context/State";
// @ts-ignore
import styles from "./Header.module.scss";

export default function Header() {
  const { isAuth } = useFirebaseAuth();

  const { numberOfQuestionsLeft } = useSenequizState();

  return (
    <div className={styles.wrapper}>
      <Link to="/">
        <img className={styles.logo} src={logo} alt="Senequiz logo" />
      </Link>

      {isAuth && (
        <>
          <Stack align="center">
            <Link to="/create">+ Add a question</Link>
            {getQuestionsLeft(numberOfQuestionsLeft)}
          </Stack>
          <button className={styles.logoutButton} onClick={logOut}>
            <Icon iconName="leave" size="small" accessibilityLabel="log out" />
          </button>
        </>
      )}
    </div>
  );
}

function getQuestionsLeft(number?: number) {
  if (number === undefined) {
    return "";
  }
  if (number === 0) {
    return "🚨 No questions left, better upload some! 🚨";
  }

  if (number < 5) {
    return `👀 only ${number} question${
      number === 1 ? "" : "s"
    } left, probably a good time to upload and get some easy points!`;
  }

  return `${number} questions left in the bank 🏦`;
}
