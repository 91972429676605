import { PowerUpActions, PowerUpState } from "src/hooks/power-ups/usePowerUps";
import { Action, combineReducers } from "src/utils/combineReducers";
import fiftyFiftyReducer from "./fiftyFiftyReducer";
import checkIfPowerUpUsedReducer from "./checkIfPowerUpUsedReducer";
import checkIfTopLeftReducer from "./checkIfTopLeftReducer";
import basicPowerUpsReducer from "./basicPowerUpsReducer";

export default function reducer(state: PowerUpState, action: Action<PowerUpActions>): PowerUpState {
  return combineReducers([fiftyFiftyReducer, checkIfPowerUpUsedReducer, checkIfTopLeftReducer, basicPowerUpsReducer])(state, action);
}
