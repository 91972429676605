import { PowerUp, PowerUpActions, PowerUpState } from "src/hooks/power-ups/usePowerUps";
import { Action } from "src/utils/combineReducers";

export default function reducer(state: PowerUpState, action: Action<PowerUpActions>): PowerUpState {
  switch (action.type) {
    case PowerUpActions.USE_DOUBLE_POINTS:
      return {
        ...state,
        selectedPowerUp: PowerUp.DOUBLE_POINTS
      };
    default:
      return state;
  }
}
