import { PowerUp, PowerUpActions, PowerUpState } from "src/hooks/power-ups/usePowerUps";
import { Action } from "src/utils/combineReducers";

export interface CheckIfTopLeftState {
  loading: boolean;
  isTopLeft: boolean | null;
  error: boolean;
}

export default function reducer(state: PowerUpState, action: Action<PowerUpActions>): PowerUpState {
  switch (action.type) {
    case PowerUpActions.IS_TOP_LEFT_STARTED:
      return {
        ...state,
        selectedPowerUp: PowerUp.IS_TOP_LEFT,
        isTopLeft: {
          loading: true,
          isTopLeft: null,
          error: false,
        }
      };
    case PowerUpActions.IS_TOP_LEFT_FINISHED:
      return {
        ...state,
        selectedPowerUp: PowerUp.IS_TOP_LEFT,
        isTopLeft: {
          loading: false,
          isTopLeft: action.isTopLeft,
          error: false,
        }
      };
    case PowerUpActions.IS_TOP_LEFT_FAILED:
      return {
        ...state,
        selectedPowerUp: null,
        isTopLeft: {
          loading: false,
          isTopLeft: null,
          error: true,
        }
      };
    default:
      return state;
  }
}
