let config: any;

export default function getConfig() {
  if (!config) {
    // const { REACT_APP_USE_CONFIG } = process.env;

    // if (REACT_APP_USE_CONFIG === "dev") {
    //   // Require has to be `config.${REACT_APP_USE_CONFIG` (i.e string assembled by environment variables)
    //   // otherwise webpack will try and bundle config.dev.json
    //   config = require(`../../config.${REACT_APP_USE_CONFIG}`);
    // } else {
    //   // @ts-ignore
    //   if (!window.env) throw new Error("No config found");
    //   // @ts-ignore
    //   config = window.env;
    // }

    config = require(`./config.dev.json`);

    config = Object.freeze(config);
  }

  return config;
}

export function useConfig() {
  try {
    return {
      config: getConfig(),
      configError: null
    };
  } catch (error) {
    return {
      config: null,
      configError: error
    };
  }
}
