export default async function createUser({
  userId,
  name
}: {
  userId: string;
  name: string;
}) {
  try {
    await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/createUser",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({ userId, name })
      }
    );

    return [{ userId, name }, null];
  } catch (error) {
    return [null, error];
  }
}
