export default async function fetchLeaderboard(weeklyLeaderboard: boolean) {
  try {
    const res = await fetch(
      `https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/fetchLeaderboard${
        weeklyLeaderboard ? "?weeklyLeaderboard=true" : ""
      }`,
      {
        method: "GET"
      }
    ).then((res) => res.json());

    return res;
  } catch (error) {
    return [];
  }
}
