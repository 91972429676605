import { createContext, useContext, useState } from "react";

type State = {
  numberOfQuestionsLeft?: number;
};

const defaultState: State = {
  numberOfQuestionsLeft: undefined
};

const StateContext =
  //@ts-ignore
  createContext<{ state: State; setState: (s: State) => void }>();

export function SeneQuizStateProvider({ children }: any) {
  const [state, setState] = useState(defaultState);

  return (
    <StateContext.Provider value={{ state, setState }}>
      {children}
    </StateContext.Provider>
  );
}

export const useSenequizState = () => useContext(StateContext).state;
export const useSetSenequizState = () => useContext(StateContext).setState;
