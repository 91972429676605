import { useState } from "react";
import { Link } from "react-router-dom";

import { createUser } from "src/api";
import useFirebaseAuth from "src/hooks/useFirebaseAuth";

import LoadingSpinner from "./LoadingSpinner";
import Auth from "./Auth";

import styles from "../Homepage.module.scss";

export default function AnswerQuestion({
  answered,
  isQuizDay,
  isNoQuestionLeft
}: any) {
  const { user, isAuth, userInfoExists, setUserInfoExists } = useFirebaseAuth();
  const [name, setName] = useState<string>("");

  if (user !== null) {
    if (
      userInfoExists === null ||
      answered === null ||
      isNoQuestionLeft === null ||
      user === undefined
    ) {
      return (
        <div className={styles.section}>
          <LoadingSpinner />
        </div>
      );
    }
  }

  if (!userInfoExists && isAuth)
    return (
      <div className={styles.section}>
        <h2>What's your name?</h2>
        <input
          type="text"
          onChange={(e) => {
            setName(e.target.value);
          }}
          value={name}
          className={styles.input}
          placeholder="Your name"
        />
        <button
          onClick={async () => {
            await createUser({ userId: user!.uid, name: name });
            setUserInfoExists(true);
          }}
        >
          Save
        </button>
      </div>
    );

  if (!isQuizDay)
    return (
      <div className={styles.section}>
        <h2>No questions today</h2>
        <p>Come back on Monday</p>
      </div>
    );

  if (!isAuth) {
    return <Auth />;
  }

  if (isNoQuestionLeft) {
    return (
      <div className={styles.section}>
        <h2>Upps.. 🤷‍♂️</h2>
        <p>There is no question to ask</p>
        <p>you need to add one!</p>
      </div>
    );
  }

  if (answered)
    return (
      <div className={styles.section}>
        <h2>Question of the day</h2>
        <p>Thanks for answering, come back tomorrow!</p>
      </div>
    );

  return (
    <div className={styles.section}>
      <h2>Question of the day</h2>
      <Link to="/quiz">
        <button>Start</button>
      </Link>
    </div>
  );
}
