import { isArray } from "lodash";
import { FiChevronLeft, FiChevronRight, FiCheck, FiX } from "react-icons/fi";

import LoadingSpinner from "./LoadingSpinner";

import styles from "../Homepage.module.scss";

export default function Leaderboard({
  leaderboards,
  leaderboardIndex,
  setLeaderboardIndex,
  todayScores
}: any) {
  return (
    <div className={styles.section}>
      {!leaderboards && <LoadingSpinner />}

      {leaderboards && leaderboards[leaderboardIndex] && (
        <>
          <div className={styles.header}>
            {leaderboardIndex === 0 ? (
              <div />
            ) : (
              <FiChevronLeft
                onClick={() => setLeaderboardIndex(leaderboardIndex - 1)}
              />
            )}
            <h2>{leaderboards[leaderboardIndex].label}</h2>
            {leaderboardIndex === leaderboards.length - 1 ? (
              <div />
            ) : (
              <FiChevronRight
                onClick={() => setLeaderboardIndex(leaderboardIndex + 1)}
              />
            )}
          </div>
          {leaderboards[leaderboardIndex].users.map((user: any, i: number) => (
            <div key={i} className={styles.user}>
              <span>{i + 1}.</span>
              <span>{user.name}</span>
              <span>{user.score}</span>
              {leaderboardIndex === leaderboards.length - 1 && (
                <Score todayScores={todayScores} user={user} />
              )}
            </div>
          ))}
        </>
      )}
    </div>
  );
}

const Score = ({ todayScores, user }: any) => {
  if (!isArray(todayScores)) return null;

  const matchingScore = todayScores.find(({ uid }) => uid === user.uid);
  if (!matchingScore) return null;

  const { score } = matchingScore;
  if (score === 2) return <FiCheck className={styles.double} />;
  if (score === 1) return <FiCheck className={styles.right} />;
  if (score === 0) return <FiX className={styles.wrong} />;

  return null;
};
