export default async function answerTodaysQuestions(
  userId: string,
  questionId: string,
  answer: string,
  powerUpUsed: boolean,
  powerUpModification: string
) {
  try {
    const res = await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/answerTodaysQuestions",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({
          userId,
          submissions: [{ questionId, answer, powerUpUsed }],
          ...powerUpModification && {powerUpModification}
        })
      }
    ).then((res) => res.json());

    return res;
  } catch (error) {
    return null;
  }
}
