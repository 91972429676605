import { useState } from 'react';

import styles from './Image.module.scss';

export default function Image({ imageUrl }: { imageUrl: string }) {
  const expanded = !!(
    window.localStorage.getItem("isImageExpanded") === "true"
  );

  const [isImageExpanded, setIsImageExpanded] = useState(expanded);

  function onImageClick() {
    window.localStorage.setItem("isImageExpanded", isImageExpanded ? "false" : "true");
    setIsImageExpanded(!isImageExpanded);
  }

  return (
    <div className={styles.imageContainer} onClick={onImageClick}>
      <img width={isImageExpanded ? "512px" : "256px"} src={imageUrl} />
    </div>
  );
}
