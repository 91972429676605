import { PowerUpActions } from "src/hooks/power-ups/usePowerUps";
import { Dispatch } from "react";
import { Action } from "src/utils/combineReducers";

export function useIsTopLeft(dispatch: Dispatch<Action<PowerUpActions>>, questionId: string) {
  return (answer: string) => {
    dispatch({ type: PowerUpActions.IS_TOP_LEFT_STARTED });
    fetch(
      `https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/checkIfTopLeft?questionId=${questionId}&answer=${answer}`,
      {
        method: "GET"
      }
    )
      .then((res) => res.json())
      .then((json) =>
        dispatch({
          type: PowerUpActions.IS_TOP_LEFT_FINISHED,
          isTopLeft: json.isTopLeft
        })
      )
      .catch(() => dispatch({ type: PowerUpActions.IS_TOP_LEFT_FAILED }));
  };
}
