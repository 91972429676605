import { isEmpty, shuffle } from 'lodash';
import { useMemo, useState } from 'react';
import { v4 as uuidv4 } from 'uuid';

import Image from '../../components/Image';
import styles from './GenerateQuestion.module.scss';

type Props = { userId?: string };

type Quiz = {
  question: string;
  correctAnswer: string;
  wrongAnswers: string[];
  source: string;
  imageUrl?: string;
};

export default function CreateQuestion({ userId }: Props) {
  const [message, setMessage] = useState<string>();
  const [isSaving, setIsSaving] = useState(false);
  const [isGenerating, setIsGenerating] = useState(false);
  const [isQuestionSaved, setIsQuestionSaved] = useState(false);

  const [questionTopic, setQuestionTopic] = useState<string>();
  const [questionType, setQuestionType] = useState("questionOnly");
  const [questionDifficulty, setQuestionDifficulty] = useState("medium");

  const [quiz, setQuiz] = useState<Quiz>();
  const [source, setSource] = useState<string>();
  const [options, setOptions] = useState<string[]>([]);

  const questionTopicPlaceholder = useMemo(
    () => shuffle(["Cars", "Books", "Stars", "Rockets", "Cats", "Dogs"])[0],
    []
  );

  const saveQuestion = async () => {
    setIsSaving(true);
    await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/createQuestion",
      {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          userId,
          questionType,
          questionId: uuidv4(),
          question: quiz?.question,
          options: options,
          source: source || undefined,
          correctOption: quiz?.correctAnswer,
          imageUrl: quiz?.imageUrl
        })
      }
    )
      .then((res) => {
        setIsSaving(false);
        setIsQuestionSaved(true);
        setTimeout(() => setMessage(""), 3000);
      })
      .catch((error) => {
        setIsSaving(false);
        setIsQuestionSaved(false);
        setMessage("Oh no, your submission errored. Try again");
        setTimeout(() => setMessage(""), 3000);
      });
  };

  const generateQuestion = async () => {
    setIsGenerating(true);
    await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/generateQuizQuestion",
      {
        method: "POST",
        headers: new Headers({ "Content-Type": "application/json" }),
        body: JSON.stringify({
          questionType,
          questionTopic,
          difficulty: questionDifficulty
        })
      }
    )
      .then(async (res) => {
        const quizQuestion: Quiz = (await res.json()).quizQuestion;

        setOptions(
          shuffle([quizQuestion.correctAnswer, ...quizQuestion.wrongAnswers])
        );
        setIsGenerating(false);
        setIsQuestionSaved(false);
        setQuiz(quizQuestion);
        setSource(quizQuestion.source);
        setTimeout(() => setMessage(""), 3000);
      })
      .catch((error) => {
        setIsGenerating(false);
        setMessage("Oh no, your submission errored. Try again");
        setTimeout(() => setMessage(""), 3000);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h2>✨ Generate a question</h2>
      </div>

      <div className={styles.options}>
        <label className={styles.label}>
          <span>Topic</span>
          <input
            type="text"
            onChange={(e) => {
              setQuestionTopic(e.target.value);
            }}
            value={questionTopic}
            className={styles.input}
            placeholder={questionTopicPlaceholder}
          />
        </label>

        <label className={styles.label}>
          <span>Difficulty</span>
          <select
            id="difficulty"
            name="difficulty"
            className={styles.input}
            value={questionDifficulty}
            style={{ height: "100%" }}
            onChange={(e) => {
              setQuestionDifficulty(e.target.value);
            }}
          >
            <option value="easy">Easy</option>
            <option value="medium">Medium</option>
            <option value="hard">Hard</option>
          </select>
        </label>

        <label className={styles.label}>
          <span>Type</span>
          <select
            id="questionType"
            name="questionType"
            className={styles.input}
            value={questionType}
            style={{ height: "100%" }}
            onChange={(e) => {
              setQuestionType(e.target.value);
            }}
          >
            <option value="questionOnly">Question only</option>
            <option value="questionWithImage">Question with image</option>
          </select>
        </label>
      </div>

      {quiz && (
        <div style={{ marginTop: "25px" }}>
          {quiz.imageUrl && <Image imageUrl={quiz.imageUrl} />}

          <div className={styles.borderBox} style={{ marginBottom: "25px" }}>
            <label className={styles.label}>
              <span>❓ Question</span>
              <h2 style={{ margin: 0 }}>{quiz.question}</h2>
            </label>
          </div>

          <div className={styles.options}>
            {[0, 1, 2, 3].map((i) => (
              <div className={styles.borderBox}>
                <label className={styles.label}>
                  <span>🔘 Option {i + 1}</span>
                  <h3 style={{ margin: 0 }}>{options[i]}</h3>
                </label>
              </div>
            ))}
          </div>

          <div style={{ marginTop: "25px" }}>
            <label className={styles.label}>
              <span>🔗 Source</span>
              <input
                type="text"
                value={source}
                className={styles.input}
                onChange={(e) => {
                  setSource(e.target.value);
                }}
              />
            </label>
          </div>
        </div>
      )}

      {questionType === "questionWithImage" && (
        <div className={styles.message}>
          🏞️ Image generation takes additional time, so please wait until it's
          generated.
        </div>
      )}

      {quiz ? (
        <div
          className={styles.options}
          style={{ justifyItems: "center", marginTop: "25px" }}
        >
          <button onClick={saveQuestion} disabled={isSaving || isQuestionSaved}>
            {isQuestionSaved ? "✅ Saved" : isSaving ? "⏳ Saving" : "💾 Save"}
          </button>

          <button onClick={generateQuestion} disabled={isGenerating}>
            {isGenerating ? "⏳ Regenerating" : "🔄 Regenerate"}
          </button>
        </div>
      ) : (
        <button
          onClick={generateQuestion}
          disabled={isEmpty(questionTopic) || isGenerating}
        >
          {isGenerating ? "⏳ Generating" : "🪄 Generate"}
        </button>
      )}

      {message && <div className={styles.message}>{message}</div>}
    </div>
  );
}
