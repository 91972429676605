import { useState } from "react";
import { v4 as uuidv4 } from "uuid";
import { Link } from "react-router-dom";

import styles from "./CreateQuestion.module.scss";

type Props = { userId?: ?string };

const sourcePlaceholders = [
  "This was once revealed to me in a dream.",
  "My uncle works at Nintendo.",
  "I made it up.",
  "Anonymous Senior Administration Official",
  "I know from looking at all the data, researching it, studying it, watching the enemy..."
];

export default function CreateQuestion({ userId }: Props) {
  const [submitting, setSubmitting] = useState(false);
  const [message, setMessage] = useState("");

  const [question, setQuestion] = useState("");
  const [source, setSource] = useState("");
  const [sourcePlaceholder] = useState(
    sourcePlaceholders[Math.floor(Math.random() * sourcePlaceholders.length)]
  );
  const [options, setOptions] = useState(["", "", "", ""]);

  const setOption = (id, string) => {
    setOptions(Object.values({ ...options, [id]: string }));
  };

  const duplicateAnswersExist = new Set(options).size !== options.length;
  const emptyAnswersExist = options.some((item) => item === "");
  const emptyQuestionExists = question === "";
  const disabled =
    emptyQuestionExists || duplicateAnswersExist || emptyAnswersExist;

  const submitName = async () => {
    setSubmitting(true);
    await fetch(
      "https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/createQuestion",
      {
        method: "POST",
        headers: new Headers({
          "Content-Type": "application/json"
        }),
        body: JSON.stringify({
          questionId: uuidv4(),
          question: question,
          options: options,
          source: source || undefined,
          correctOption: options[0],
          userId
        })
      }
    )
      .then((res) => {
        setSubmitting(false);
        setQuestion("");
        setOptions(["", "", "", ""]);
        setMessage("Question saved successfully");
        setSource("");
        setTimeout(function () {
          setMessage("");
        }, 3000);
      })
      .catch((error) => {
        setSubmitting(false);
        setMessage("Oh no, your submission errored. Try again");
        setTimeout(function () {
          setMessage("");
        }, 3000);
      });
  };

  return (
    <div className={styles.wrapper}>
      <div className={styles.titleContainer}>
        <h2>➕ Add a question</h2>
        <Link to="/generate">✨ Generate a question</Link>
      </div>
      {message && <div className={styles.message}>{message}</div>}
      <label className={styles.label}>
        <span>Question</span>
        <input
          type="text"
          onChange={(e) => {
            setQuestion(e.target.value);
          }}
          value={question}
          className={styles.input}
          placeholder="What is 2 + 2?"
        />
      </label>
      <div className={styles.options}>
        {[0, 1, 2, 3].map((i) => (
          <label className={styles.label}>
            <span>{i ? `Incorrect Option ${i}` : "Correct option"}</span>
            <input
              type="text"
              onChange={(e) => {
                setOption(i, e.target.value);
              }}
              value={options[i]}
              className={styles.input}
            />
          </label>
        ))}
      </div>
      <label className={styles.label}>
        <span>Source</span>
        <input
          type="text"
          onChange={(e) => {
            setSource(e.target.value);
          }}
          value={source}
          className={styles.input}
          placeholder={sourcePlaceholder}
        />
      </label>
      <button onClick={submitName} disabled={disabled || submitting}>
        {submitting ? "Saving" : "Save"}
      </button>
    </div>
  );
}
