import { PowerUpActions } from "src/hooks/power-ups/usePowerUps";
import { Dispatch } from "react";
import { Action } from "src/utils/combineReducers";

export function getIsPowerUpUsed(dispatch: Dispatch<Action<PowerUpActions>>) {
  return (userId: string) => {
    dispatch({ type: PowerUpActions.IS_POWER_UP_USED_LOADING });
    fetch(
      `https://frrc2gvm6a.execute-api.eu-west-1.amazonaws.com/dev/api/checkIfPowerUpUsed?userId=${userId}`,
      {
        method: "GET"
      }
    )
      .then((res) => res.json())
      .then((json) =>
        dispatch({
          type: PowerUpActions.IS_POWER_UP_USED_FINISHED,
          isPowerUpUsed: json.isPowerUpUsed
        })
      )
      .catch(() => dispatch({ type: PowerUpActions.IS_POWER_UP_USED_FAILED }));
  };
}
