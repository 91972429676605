import classnames from "classnames";
import styles from "./QuestionOption.module.scss";

export default function QuestionOption({ value, onClick, active, isCorrect }) {
  return (
    <button
      className={classnames(styles.wrapper, { [styles.active]: active }, isCorrect !== undefined ? isCorrect ? styles.correct : styles.incorrect : undefined)}
      onClick={onClick}
    >
      {value}
    </button>
  );
}
